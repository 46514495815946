<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Open <b>Odyssey</b>, go to the <b>Labs</b> tab, find the <i>Acids & Bases section</i>, and
        click on <b>K2 Comparing Oxoacids.</b> Follow the instructions on the screen and click on
        all the available buttons.
      </p>

      <p class="mb-3">
        a) What does the blueness indicated on the polarity maps of the chemicals?
        <v-select
          v-model="inputs.input1"
          class="my-1 pl-2"
          :items="options1"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-3">
        b) For the second set of molecules, which is the most acidic?
        <v-select
          v-model="inputs.input2"
          class="my-1 pl-2"
          :items="options2and3"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p>
        c) For the second set of molecules, which is the least acidic?
        <v-select
          v-model="inputs.input3"
          class="my-1 pl-2"
          :items="options2and3"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUCI1LC_Q4S2_Q9',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      options1: [
        {text: 'Electron poor areas of the molecule', value: 'a'},
        {text: 'Two of these', value: 'b'},
        {text: 'Lone pairs of electrons', value: 'c'},
        {text: 'Electron rich areas of the molecule', value: 'd'},
        {text: 'Potential acidic hydrogen atoms', value: 'e'},
      ],
      options2and3: [
        {text: 'Formic acid', value: 'a'},
        {text: 'Methanol', value: 'b'},
        {text: 'Phosphoric acid', value: 'c'},
        {text: 'Phenol', value: 'd'},
        {text: 'Sulfuric acid', value: 'e'},
      ],
    };
  },
};
</script>
